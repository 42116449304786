import React from "react";
import Page from "../containers/page";
import SupportersList from "../components/supporters";

function sortList(arry) {
  return arry.sort((a, b) => {
    let a_name = a.name.split(" ")[a.name.split(" ").length - 1];
    let b_name = b.name.split(" ")[b.name.split(" ").length - 1];
    if (a_name < b_name) {
      return -1;
    }
    if (a_name > b_name) {
      return 1;
    }
    return 0;
  });
}

const Supporters = () => {
  const committee = sortList([{ name: "Molly Aspan" }, { name: "Jennifer Annis" }, { name: "Craig Buchan" }, { name: "Erin & Robbie Butler" }, { name: "Karen Callahan" }, { name: "Jordan Dalgleish" }, { name: "Sam Daniel" }, { name: "Dr. Kevin and Julie Dukes" }, { name: "Mary Fitzgerald" }, { name: "Chip & Margarita Gaberino" }, { name: "Rachel Gusman", title: "Co-chair" }, { name: "Elizabeth Hall", title: "Treasurer" }, { name: "Jo Lynn Jeter" }, { name: "Dave & Ginger Kollman" }, { name: "Hugh Robert", title: "Co-chair" }, { name: "Stephen Rodolf" }, { name: "Joel Wohlgemuth" }]);


  return (
    <Page pageTitle="Supporters" description="">
      <section className="py-24">
        <div className="flex justify-center items-center">
          <div className="w-full md:w-2/3 px-6">
            <h1>2022 Campaign Committee</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 pt-6 gap-4">
              {committee.map((supporter, index) => (
                <div className="w-full p-4 text-center bg-light_gray" key={index}>
                  <h3 className="text-xl">{supporter.name}</h3>
                  {supporter.title && <span>{supporter.title}</span>}
                </div>
              ))}
            </div>
            <h2 className="pt-6">Supporters</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 pt-6 gap-4">
              {SupportersList.map((supporter, index) => (
                <div className="w-full p-4 text-center bg-light_gray" key={index}>
                  <h3 className="text-xl">{supporter}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section >
    </Page >
  );
}

export default Supporters;